import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';



var swiper = new Swiper('.swiper-container', {
    spaceBetween: 30,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});

var swiper = new Swiper('.swiper-container_gateau', {
    autoplay: {
        "delay": 5000,
    },
    spaceBetween: 30,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});