import {
    TweenMax,
    TimelineMax,
    gsap,
    Expo,
    TweenLite
} from 'gsap'


let w = window.innerWidth;
let ButtonWhatsapp = document.getElementById('button_whatsapp');
let ButtonClose = document.getElementById('close');
let containerChat = document.getElementById('container_chat_whatsapp');

console.log(w);
if (w <= 728) {
    formAction();
}

let visibleblock;


ButtonWhatsapp.addEventListener('click', () => {
    visibleblock = containerChat.dataset.index;
    opencontainerWhatsapp();
}, true)


ButtonClose.addEventListener('click', () => {
    closeWathsapp();
})

const opencontainerWhatsapp = () => {
    if (visibleblock == 'invisible') {
        containerChat.dataset.index = 'visible';
        openWathsapp()
    } else {
        containerChat.dataset.index = 'invisible';
        closeWathsapp()

    }
}

const closeWathsapp = () => {
    var t1 = new TimelineMax();
    t1.to("#container_chat_whatsapp", 0.4, { opacity: 0, display: 'none', y: '50px', ease: Expo.easeOut })
}
const openWathsapp = () => {
    var t1 = new TimelineMax();
    t1.to("#container_chat_whatsapp", 0.4, { opacity: 1, display: 'block', y: '0', ease: Expo.easeOut })
}

function formAction() {
    const form = document.querySelector('.form_link');
    return form.action = 'https://api.whatsapp.com/send?phone=+32485590218';
}