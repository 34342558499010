import {
    TweenMax,
    TimelineMax,
    gsap,
    Expo,
    TweenLite
} from 'gsap'

end_video();
replay();
muted_video();
unmuted_video();

var tl = new TimelineMax();

function end_video() {
    document.getElementById('myVideo').addEventListener('ended', myHandler, false);
    function myHandler(e) {
        var tl = new TimelineMax();
        tl.to("#myVideo", 0.4, {
            opacity: 0,
            ease: Expo.out,
        }, 0)
    }
}



function replay() {
    document.getElementById('replay').addEventListener("click", function () {
        document.getElementById('myVideo').currentTime = '0';
        document.getElementById('myVideo').play();
        var tl = new TimelineMax();
        tl.to("#myVideo", 0.4, {
            opacity: 1,
            y: 50,
            ease: Expo.out,
        }, 0)
    })
}



function muted_video() {
    document.getElementById('muted').addEventListener("click", function () {
        document.getElementById('myVideo').muted = false;
        document.getElementById('muted').style.display = "none"
        document.getElementById('unmuted').style.display = "block"

    })
}

function unmuted_video() {
    document.getElementById('unmuted').addEventListener("click", function () {
        document.getElementById('myVideo').muted = true;
        document.getElementById('muted').style.display = "block"
        document.getElementById('unmuted').style.display = "none"

    })
}