import ScrollToPlugin from "gsap/ScrollToPlugin";
import {
    TweenMax,
    TimelineMax,
    gsap,
    Expo,
    TweenLite
} from 'gsap'

gsap.registerPlugin(ScrollToPlugin);


const link = document.querySelectorAll('.nav__el a')

for (let i = 0; i < link.length; i++) {
    const el = link[i];

    el.addEventListener('click', function (e) {
        e.preventDefault()
        const linkTo = this.getAttribute('href')

        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: linkTo,
                offsetY: 100
            }
        })
        menuCloseClick();
    })
}



var tl = new TimelineMax();

tl.set(".navbar_menu-content", {
    display: 'none',
    x: '-100%',
})


const clickMenuOpen = document.getElementById('menu_open_eventClick');
const clickMenuClose = document.getElementById('menu_close_eventClick');



clickMenuOpen.addEventListener("click", menuOpenClick, true)
clickMenuClose.addEventListener("click", menuCloseClick, true)


function menuOpenClick() {
    tl.to(".navbar-menu", 0, { zIndex: 1, display: 'block', ease: Expo.easeIn });
    tl.to(".navbar_menu-content", 0.4, { x: 0, display: 'block', ease: Expo.out });
    console.log('au click');
}


function menuCloseClick() {
    tl.to(".navbar_menu-content", 0.3, { x: '-100%', display: 'none', ease: Expo.out });
    tl.to(".navbar-menu", { zIndex: -1, display: 'none', ease: Expo.easeIn });
    console.log('au click');
}
