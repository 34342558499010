import ScrollMagic from "scrollmagic"
import {
    TweenMax,
    TimelineMax,
    gsap,
    Expo,
    TweenLite
} from 'gsap'
import {
    ScrollMagicPluginGsap
} from 'scrollmagic-plugin-gsap'

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

var controller = new ScrollMagic.Controller();
var tl = new TimelineMax();

tl.set("header, h1, .service", {
    opacity: 0,
})
tl.set(".header_content h2, .header_content h3, .header_content p,  .button", {
    opacity: 0,
    x: "-50%"
})
tl.set(".biographie-content_txt, .gateau-content_txt, #button_whatsapp", {
    opacity: 0,
    y: "100px"
})
tl.set(".service_swiper", {
    x: "-30%",
})


window.onload = function () {
    tl.to("header", 1.2, {
        opacity: 1,
        ease: Expo.out,
    }, 0.2)
    tl.to("h1", 1.2, {
        opacity: 1,
        ease: Expo.out,
    }, 3)
    tl.to(".header_content h2, .header_content h3, .header_content p, .button", 0.8, {
        opacity: 1,
        x: 0,
        ease: Expo.out,
    }, 3.5)
}


var scene = new ScrollMagic.Scene({
    triggerElement: '#biographie',
    offset: 150,
    reverse: false,
})
    .setTween(new TimelineMax().add([
        TweenLite.to(".biographie-content_txt", 0.6, {
            opacity: 1,
            y: 0,
        }),

        TweenLite.to("#button_whatsapp", 0.3, {
            opacity: 1,
            y: 0,
        }),
    ]));
scene.addTo(controller);

var scene2 = new ScrollMagic.Scene({
    triggerElement: '#gateau',
    offset: 150,
    reverse: false,
})
    .setTween(".gateau-content_txt", 0.6, {
        opacity: 1,
        y: 0
    })

    // .addIndicators({
    //     name: "biographie (duration: 0)"
    // })

    .addTo(controller);

var scene3 = new ScrollMagic.Scene({
    triggerElement: '#service',
    offset: 70,
    reverse: false,
})

    .setTween(new TimelineMax().add([
        TweenLite.to(".service", 0.7, {
            opacity: 1,
        }),

        TweenLite.to(".service_swiper", 0.7, {
            x: 0,
        }),

    ]));


scene3.addTo(controller);
